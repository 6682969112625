<template>
  <div class="unsignBank">
    <div class="unsignBankTitle">
      <!-- <div class="myUnSignBank">
        <p>银行解约</p>
      </div> -->
    </div>
    <div class="unsignBankContent">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item label="选择银行" prop="bankId">
          <el-radio-group v-model="form.bankId">
            <el-radio :label="1">赣州银行</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="tipsContent">
          <p>温馨提示：请选择需要解约的银行。</p>
        </div>
        <div class="submitButton">
          <el-button type="danger" @click="submitApply()">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      form: {
        bankId: 1
      },
      rules: {
        bankId: [{ required: true, message: "请选择银行", trigger: "change" }]
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  methods: {
    submitApply() {
      protocolFwd.param_userUnSign.param.bankId = 1;
      protocolFwd.param_userUnSign.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocolFwd.param_userUnSign).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.$EL_MESSAGE(message);
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style scoped>
.unsignBank {
  padding: 0 15px;
}
.unsignBank .myUnSignBank {
  float: left;
  line-height: 40px;
}
.unsignBankTitle {
  overflow: hidden;
}
.unsignBankTitle .myUnSignBank p {
  font-size: 16px;
}
.unsignBankContent {
  width: 80%;
}
.tipsContent {
  margin-bottom: 15px;
  line-height: 30px;
  color: #9999a2;
  font-weight: 700;
}
</style>
